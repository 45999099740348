import imagesLoaded from 'imagesloaded';
import {
	TweenLite,
	CSSPlugin,
	Power0,
	Power1,
	Power2,
	Power3,
	Power4,
	Linear,
	Quad,
	Cubic,
	Quart,
	Quint,
	Strong,
	Elastic,
	Back,
	SteppedEase,
	Bounce,
	Sine,
	Expo,
	Circ
} from "gsap/all";

const gsapPlugins = [CSSPlugin];

const easings = {
	Power0,
	Power1,
	Power2,
	Power3,
	Power4,
	Linear,
	Quad,
	Cubic,
	Quart,
	Quint,
	Strong,
	Elastic,
	Back,
	SteppedEase,
	Bounce,
	Sine,
	Expo,
	Circ,
};

class ScrollInView {
	constructor(elements) {
		this.initScrollInView(elements);
	}

	initScrollInView(elements) {
		this.windowHeight = window.innerHeight;

		// Minimum distance required between top of the element and the bottom of the page to trigger an enter animation
		this.minVisibleHeight = 150;

		// Class which is added to the element when its animation has started
		this.animHasStartedClass = "anim-started";

		// Html attr used to detect elements which are grouped
		this.groupAttr = "data-scroll-in-view-group";

		// Html attr used to store the delay to start the element animation
		this.delayAttr = "data-scroll-in-view-delay";

		// Convert nodeList to array
		this.scrollingElements = Array.prototype.slice.call(elements);

		if (this.scrollingElements.length) {
			this.scrollInViewElementsArray = [];
			this.remainingElementsToAnimCount = this.scrollingElements.length;

			for (let i = 0, length = this.scrollingElements.length; i < length; i++) {
				const el = this.scrollingElements[i];

				let attr = {
					delay: 0,
					duration: 1,
					ease: "Expo.easeOut",
					group: null,
					trigger: false,
				};
				if (el.hasAttribute("data-scroll")) {
					attr = el.getAttribute("data-scroll");
					try {
						attr = JSON.parse(attr);
					} catch (e) {
						console.error("Error while parsing json: ", attr, e, el);
					}
				}

				this.scrollInViewElementsArray[i] = {
					index: i,
					el: el,
					height: parseInt(el.getBoundingClientRect().height, 10),
					top: parseInt(el.getBoundingClientRect().top + window.pageYOffset, 10),
					delay: !isNaN(attr.delay) ? parseFloat(attr.delay, 10) : 0,
					duration: !isNaN(attr.duration) ? parseFloat(attr.duration, 10) : 1,
					ease: attr.ease || "Expo.easeOut",
					group: !isNaN(attr.group) ? parseInt(attr.group, 10) : null,
					trigger: attr.trigger ? attr.trigger : false,
					hasStarted: false,
				};
			}
		}

		this.resize();
		this.bindEvents();
		this.scroll();
	}

	bindEvents() {
		this.scrollFn = this.scroll.bind(this);
		this.resizeFn = this.resize.bind(this);
		window.addEventListener('scroll', this.scrollFn);
		window.addEventListener('resize', this.resizeFn);
		window.addEventListener('load', this.resizeFn);

        if('fonts' in document) {
            document.fonts.ready.then(this.resizeFn);
        }

        if(this.scrollingElements.length) {
            this.imagesLoaded = imagesLoaded(document.body);
            this.imagesLoaded.on('progress', this.resizeFn);
        }
	}

	unbindEvents() {
		window.removeEventListener('scroll', this.scrollFn);
		window.removeEventListener('resize', this.resizeFn);
		window.removeEventListener('load', this.resizeFn);
        this.imagesLoaded.off('progress', this.resizeFn);
		this.scrollFn = null;
		this.resizeFn = null;
	}

	scroll() {
		if (this.remainingElementsToAnimCount !== 0 && window.innerWidth >= 1200) {
			for (let i = 0, length = this.scrollInViewElementsArray.length; i < length; i++) {
				const element = this.scrollInViewElementsArray[i];

				if (element && this.canStartScrollInViewAnimation(element)) {
					// Control if animation hasn't started
					if (!element.hasStarted) {
						element.hasStarted = true;

						this.animateScrollInViewElement(element);

						if (element.group) {
							for (let j = 0, length = this.scrollInViewElementsArray.length; j < length; j++) {
								const groupEl = this.scrollInViewElementsArray[j];

								if (groupEl && groupEl.group && parseInt(groupEl.group, 10) == element.group) {
									groupEl.hasStarted = true;
									this.animateScrollInViewElement(groupEl);
								}
							}
						}
					}
				}
			}
		}
	}

	isInScreen(element) {
		let inScreen = false;
		const scrollOffset = window.pageYOffset;

		// Top of the element is off-screen
		if (scrollOffset > element.top) {
			// Element if off-screen (higher than screen top)
			if (scrollOffset > element.top + element.height) {
				inScreen = false;
			}
			// Element bottom part is still in screen
			else {
				inScreen = true;
			}
		} else {
			// Element is visible at the bottom of the screen
			if (this.windowHeight + scrollOffset > element.top) {
				inScreen = true;
			}
			// Element is not yet visible (lower than screen bottom)
			else {
				inScreen = false;
			}
		}
		return inScreen;
	}

	canStartScrollInViewAnimation(element) {
		// if (element.el.classList.contains("news-slider__slide1")) {
		//  console.log(element, this.isInScreen(element), this.windowHeight + window.pageYOffset - element.top, this.minVisibleHeight);
		// }
		return this.isInScreen(element) && this.windowHeight + window.pageYOffset - element.top >= this.minVisibleHeight;
	}

	animateScrollInViewElement(element) {
		let isInverted = false;
		let opacity = 1;

		if (element.el.classList.contains("inv")) {
			isInverted = true;
		}

		if (isInverted) {
			TweenLite.to(element.el, element.duration, {
				x: 0,
				y: 0,
				rotation: 0,
				rotationX: 0,
				rotationY: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: opacity,
				ease: this.getEaseFunction(element.ease),
				force3D: true,
				delay: element.delay,
				onComplete: () => {
					if (element.trigger) {
						document.dispatchEvent(new CustomEvent(element.trigger, {detail: element}));
					}

					element.el.classList.add("scroll-in-view-anim-ended");
					this.removeElementFromScrollInViewElementsArray(element);
				},
			});
		} else {
			TweenLite.to(element.el, element.duration, {
				x: 0,
				y: 0,
				rotation: 0,
				rotationX: 0,
				rotationY: 0,
				scaleX: 1,
				scaleY: 1,
				opacity: opacity,
				ease: this.getEaseFunction(element.ease),
				force3D: true,
				delay: element.delay,
				onComplete: () => {
					if (element.trigger) {
                        document.dispatchEvent(new CustomEvent(element.trigger, {detail: element}));
					}

					element.el.classList.add("scroll-in-view-anim-ended");
					this.removeElementFromScrollInViewElementsArray(element);
				},
			});
		}
	}

	removeElementFromScrollInViewElementsArray(element) {
		if (this.scrollInViewElementsArray[element.index]) {
			this.scrollInViewElementsArray[element.index] = null;
			this.remainingElementsToAnimCount--;
		}
	}

	getEaseFunction(ease) {
		const easeStr = ease.split(".");
		const easeFamily = easeStr[0];
		const easeType = easeStr[1];

		return easings[easeFamily][easeType];
	}

	resize() {
		this.windowHeight = window.innerHeight;

        if(this.remainingElementsToAnimCount > 0 && this.scrollInViewElementsArray.length) {
            this.scrollInViewElementsArray.forEach((scrollInViewElement) => {
                if(scrollInViewElement !== null) {
                    scrollInViewElement.height = parseInt(scrollInViewElement.el.getBoundingClientRect().height, 10);
                    scrollInViewElement.top = parseInt(scrollInViewElement.el.getBoundingClientRect().top + window.pageYOffset, 10);
                }
            });
        }
	}

	destroy() {
		this.unbindEvents();
		this.scrollingElements.length = 0;
		this.scrollInViewElementsArray.length = 0;
	}
}

export default ScrollInView;