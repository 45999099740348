class MenuToggler {
    constructor(el = null) {
        this.el = el;

        if(this.el) {
            this.init();
        }
    }

    init() {
        this.mainMenu = document.querySelector('.main-menu-wrapper');

        this.bindEvents();
    }

    bindEvents() {
        this.el.addEventListener('click', this.onMenuToggle.bind(this));
        window.addEventListener('menu-toggler:close', this.onClose.bind(this));
        document.addEventListener('click', this.onClickOutside.bind(this));
    }

    onOpen() {
        this.el.classList.add('menu-toggler--active');
        window.dispatchEvent(new Event('menu-toggler:opening'));
    }

    onClose() {
        this.el.classList.remove('menu-toggler--active');
        window.dispatchEvent(new Event('menu-toggler:closing'));
    }

    onMenuToggle() {
        if(this.el.classList.contains('menu-toggler--active')) {
            this.onClose();
        }
        else {
            this.onOpen();
        }
    }

    onClickOutside(e) {
        if(!this.el.contains(e.target)) {
            this.onClose();
        }
    }
}

export default MenuToggler;