class SheetsSlider {
    constructor(el = null) {
        this.el = el;

        if(this.el) {
            this.init();
        }
    }

    init() {
        this.slides = [...this.el.querySelectorAll('.sheets-slider__slide')];
        this.previousBtn = this.el.querySelector('.sheets-slider__prev-btn');
        this.nextBtn = this.el.querySelector('.sheets-slider__next-btn');

        this.currentSlideIndex = 0;
        this.isTransitioning = false;
        this.nextSlideClass = 'sheets-slider__slide--next';
        this.currentSlideClass = 'sheets-slider__slide--current';
        this.previousSlideClass = 'sheets-slider__slide--prev';
        this.activeSideSlideClass = 'sheets-slider__slide--active';
        this.activeCurrentPrevSlideClass = 'sheets-slider__slide--active-prev';
        this.activeCurrentNextSlideClass = 'sheets-slider__slide--active-next';
        this.noTransitionSlideClass = 'sheets-slider__slide--no-transition';

        if(this.slides.length) {
            this.bindEvents();
        }
    }

    bindEvents() {
        this.onPreviousBtnClickFn = this.onPreviousBtnClick.bind(this);
        this.onNextBtnClickFn = this.onNextBtnClick.bind(this);

        this.previousBtn.addEventListener('click', this.onPreviousBtnClickFn);
        this.nextBtn.addEventListener('click', this.onNextBtnClickFn);
    }

    onPreviousBtnClick(e) {
        if(this.isTransitioning) return;
        
        this.onTransitionStart();

        const indexes = this.getNextPrevSlidesIndexes(this.currentSlideIndex);
        const onTransitionEndFn = () => {
            this.slides[indexes.previous].removeEventListener('transitionend', onTransitionEndFn);

            this.slides[indexes.current].classList.add(this.noTransitionSlideClass);
            this.slides[indexes.previous].classList.add(this.noTransitionSlideClass);

            this.clearClasses([this.currentSlideClass, this.previousSlideClass, this.nextSlideClass, this.activeSideSlideClass, this.activeCurrentPrevSlideClass, this.activeCurrentNextSlideClass]);

            this.slides[indexes.previous].classList.add(this.currentSlideClass);
            this.slides[indexes.current].classList.add(this.previousSlideClass);
            
            setTimeout(() => {
                this.clearClasses(this.noTransitionSlideClass);

                this.updateNextPrevSlides();
                this.onTransitionEnd();
            }, 0);
        };

        this.slides[indexes.previous].addEventListener('transitionend', onTransitionEndFn);

        this.slides[indexes.previous].classList.add(this.activeSideSlideClass);
        this.slides[indexes.current].classList.add(this.activeCurrentPrevSlideClass);
        
        this.el.style.height = `${this.slides[indexes.previous].offsetHeight}px`;

        this.currentSlideIndex = indexes.previous;
    }

    onNextBtnClick(e) {
        if(this.isTransitioning) return;

        this.onTransitionStart();

        const indexes = this.getNextPrevSlidesIndexes(this.currentSlideIndex);
        const onTransitionEndFn = () => {
            this.slides[indexes.next].removeEventListener('transitionend', onTransitionEndFn);
            
            this.slides[indexes.next].classList.add(this.noTransitionSlideClass);
            this.slides[indexes.current].classList.add(this.noTransitionSlideClass);
            
            this.clearClasses([this.currentSlideClass, this.previousSlideClass, this.nextSlideClass, this.activeSideSlideClass, this.activeCurrentPrevSlideClass, this.activeCurrentNextSlideClass]);

            this.slides[indexes.next].classList.add(this.currentSlideClass);
            this.slides[indexes.current].classList.add(this.previousSlideClass);
            
            setTimeout(() => {
                this.clearClasses(this.noTransitionSlideClass);

                this.updateNextPrevSlides();
                this.onTransitionEnd();
            }, 0);
        };
        
        this.slides[indexes.next].addEventListener('transitionend', onTransitionEndFn);

        this.slides[indexes.next].classList.add(this.activeSideSlideClass);
        this.slides[indexes.current].classList.add(this.activeCurrentNextSlideClass);

        this.el.style.height = `${this.slides[indexes.next].offsetHeight}px`;

        this.currentSlideIndex = indexes.next;
    }

    onTransitionStart(e) {
        this.isTransitioning = true;
    }

    onTransitionEnd() {
        this.isTransitioning = false;
    }
    
    getNextPrevSlidesIndexes(currentSlideIndex) {
        const nextSlideIndex = currentSlideIndex + 1 < this.slides.length ? currentSlideIndex + 1 : 0;
        const previousSlideIndex = currentSlideIndex - 1 > -1 ? currentSlideIndex - 1 : this.slides.length - 1;

        return {current: currentSlideIndex, previous: previousSlideIndex, next: nextSlideIndex};
    }

    updateNextPrevSlides() {
        const indexes = this.getNextPrevSlidesIndexes(this.currentSlideIndex);
        
        this.slides[indexes.next].classList.add(this.nextSlideClass);
        this.slides[indexes.previous].classList.add(this.previousSlideClass);
    }

    clearClasses(classes = []) {
        if(!Array.isArray(classes)) {
            classes = [classes];
        }

        if(classes.length) {
            this.slides.forEach((slide) => {
                slide.classList.remove(...classes);
            });
        }
    }
}

export default SheetsSlider;