class MainMenu {
    constructor(el = null) {
        this.el = el;

        if(this.el) {
            this.init();
        }
    }

    init() {
        this.mainMenu = document.querySelector('.main-menu-wrapper');
        this.links = this.mainMenu.querySelectorAll('a');

        this.bindEvents();
    }

    bindEvents() {
        this.links.forEach((link) => link.addEventListener('click', this.onLinkClick.bind(this)));
        window.addEventListener('menu-toggler:opening', this.onOpen.bind(this));
        window.addEventListener('menu-toggler:closing', this.onClose.bind(this));
    }

    onOpen() {
        this.mainMenu.classList.add('main-menu-wrapper--opened');
    }

    onClose() {
        this.mainMenu.classList.remove('main-menu-wrapper--opened');
    }

    onLinkClick() {
        this.onClose();
        window.dispatchEvent(new Event('menu-toggler:close'));
    }
}

export default MainMenu;