import MainMenu from './components/main-menu';
import MenuToggler from './components/menu-toggler';
import HeroSlider from './components/hero-slider';
import SheetsSlider from './components/sheets-slider';
// import JobsList from './components/jobs-list';
// import ProjectsList from './components/projects-list';
import ProjectList from './components/project-list';
import TeamMembersList from './components/team-members-list';
import HexagonsArea from './components/hexagons-area';
import ScrollInView from "./components/scroll-in-view";

const initComponents = (components) => {
    components.forEach((c) => {
        if(c.elements.length) {
            if(c.multipleInstances) {
                c.elements.forEach((el) => {
                    new c.class(el);
                });
            }
            else {
                new c.class(c.elements);
            }
        }
    });
};
const initAnimations = () => {
    window.dispatchEvent(new Event('hero-slider:activate'));
};
const onDOMReady = () => {
    // Components initialization
    const components = [
        {class: MainMenu, elements: [...document.querySelectorAll('.main-menu-wrapper')], multipleInstances: true},
        {class: MenuToggler, elements: [...document.querySelectorAll('.menu-toggler')], multipleInstances: true},
        {class: HeroSlider, elements: [...document.querySelectorAll('.hero-slider')], multipleInstances: true},
        {class: SheetsSlider, elements: [...document.querySelectorAll('.sheets-slider')], multipleInstances: true},
        // {class: JobsList, elements: [...document.querySelectorAll('.jobs-list')], multipleInstances: true},
        // {class: ProjectsList, elements: [...document.querySelectorAll('.projects-list')], multipleInstances: true},
        {class: TeamMembersList, elements: [...document.querySelectorAll('.team')], multipleInstances: true},
        {class: HexagonsArea, elements: [...document.querySelectorAll('.hexagons-area')], multipleInstances: true},
        {class: ScrollInView, elements: [...document.querySelectorAll('.scroll-in-view')], multipleInstances: false},
    ];

    initComponents(components);
    initAnimations();
};

if(document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', onDOMReady);
}
else {
    onDOMReady();   
}