class HeroSlider {
    constructor(el = null) {
        this.el = el;

        if(this.el) {
            this.init();
        }
    }

    init() {
        this.images = [...this.el.querySelectorAll('.hero-slider__image')];
        this.overlay = this.el.querySelector('.hero-slider__overlay');
        
        this.currentImageIndex = 0;
        this.activeClass = 'hero-slider__image--active';
        
        if(this.images.length) {
            this.bindEvents();
        }
    }

    bindEvents() {
        this.overlay.addEventListener('transitionend', this.onActivationEnd.bind(this));
        window.addEventListener('hero-slider:activate', this.onActivation.bind(this));
    }

    bindSliderEvents() {
        this.el.addEventListener('transitionend', this.onTransitionEnd.bind(this));
    }

    onActivation() {
        this.el.classList.add('hero-slider--activated');
    }

    onActivationEnd() {
        this.bindSliderEvents();
        this.onTransitionEnd();
    }

    onTransitionEnd(e = {target: null}) {
        const {target} = e;
        
        if(!target || target === this.images[this.currentImageIndex]) {
            const leavingImage = this.currentImageIndex;
            const enteringImage = this.currentImageIndex + 1 < this.images.length ? this.currentImageIndex + 1 : 0;

            setTimeout(() => {
                this.images[leavingImage].classList.remove(this.activeClass);
                this.images[enteringImage].classList.add(this.activeClass);

                this.currentImageIndex = enteringImage;
            }, 0);
        }
    }
};

export default HeroSlider;