import * as basicLightbox from 'basiclightbox';
import '~/basiclightbox/dist/basicLightbox.min.css';

document.addEventListener("DOMContentLoaded", () => {
    // Navigation dans les catégories 
    let navProjects = document.querySelectorAll('.projects-list-menu > a');
    let projects = document.querySelectorAll('.projects-list__item');
    let projectsDetailed = document.querySelectorAll('.project-list-detailed__content');
    let lightboxImagesNode = document.querySelectorAll('.lightbox');
    let lightboxImages = Array.prototype.slice.call(lightboxImagesNode);
    let lightboxInstance = null;
    let projectsCategoryDetailed = document.querySelectorAll('.project-list-detailed');

    lightboxImages.forEach(lightBoxImages => lightBoxImages.addEventListener('click', function(e) {
        e.preventDefault();

    
        const rel = lightBoxImages.hasAttribute('rel') ? lightBoxImages.getAttribute('rel') : null;
        let tpl = '';
        let relatedElements = [];
        
        if(rel) {

            relatedElements = getRelatedElements(rel);        
            const content = lightBoxImages.nextElementSibling.innerHTML;

            relatedElements.forEach((el) => {
                const className = el === lightBoxImages ? 'lightbox-content-element current' : 'lightbox-content-element d-none';
                const href = el.getAttribute('href');
                const videoLink = el.nextSibling;
                
                if(href.match(/^http(s)?:\/\//)) {
                    tpl += `<iframe class="${className}"" src="${href}"></iframe>`;
                } else if (videoLink.href){
                    tpl += `<div class="box ${className}"><a href="${videoLink}" target="_blank"><img src="${href}" alt=""/></a></div>`;
                } else {
                    tpl += `<div class="box ${className}"><img src="${href}" alt=""/></div>`;
                }
            });

            tpl += `
                    <div class="lightbox-content"><div class="lightbox-content__content">${content}</div></div>
                    <button class="prev-btn">Précédent</button>
                    <button class="next-btn">Suivant</button>
                    <button class="close-btn"></button>
                `;
        }
        lightboxInstance = basicLightbox.create(`
                <div class="lightbox__content-container">
                    ${tpl}    
                </div>
            `, {
                onShow: (instance) => {
                    if(relatedElements.length) {
                        instance.element().querySelector('.prev-btn').addEventListener('click', onPrevLightboxContentClick);
                        instance.element().querySelector('.next-btn').addEventListener('click', onNextLightboxContentClick);
                        instance.element().querySelector('.close-btn').addEventListener('click', closePopup);
                    }
                },
                onClose: (instance) => {
                    if(relatedElements.length) {
                        
                        instance.element().querySelector('.prev-btn').removeEventListener('click', onPrevLightboxContentClick);
                        instance.element().querySelector('.next-btn').removeEventListener('click', onNextLightboxContentClick);
                    }
                }
            });
            lightboxInstance.show();
    }));

    projectsDetailed.forEach( projectDetailed => projectDetailed.addEventListener('click', function(e){
        projectDetailed.classList.add('projects-list__item--opened');
        let attributeSelected = this.getAttribute('data-category');

        navProjects.forEach(nav => {
            if(nav.getAttribute('data-category') === attributeSelected) {
                nav.classList.add('button--active');
            } else {
                document.querySelector('.button--first-category').classList.remove('button--active');
                e.target.classList.remove('button--active');
            }
        });
    }));

    // Loop Click sur les boutons de navigation - Check si les attributs correspondent 
    navProjects.forEach( nav => nav.addEventListener('click', function(e){
        e.preventDefault();
        
        let attributeSelected = this.getAttribute('data-category');
        Array.from(document.querySelectorAll(`.projects-list-menu .button--primary`)).filter(item => item != nav).forEach(item => item.classList.remove('button--active'));

        projectsCategoryDetailed.forEach(projectCategory => {
            if(projectCategory.getAttribute('data-category') === attributeSelected) {
                projectCategory.classList.add('project-list-detailed--active');
                nav.classList.toggle('button--active');
            } else {
                projectCategory.classList.remove('project-list-detailed--active');
                nav.classList.toggle('button--active');
            }
        });

    }));

    // Loop MouseEnter sur les boutons de navigation - Check si les attributs correspondent 
    navProjects.forEach( nav => nav.addEventListener('mouseenter', function(e){
        e.preventDefault();

        let attributeSelected = this.getAttribute('data-category');
        
        projects.forEach(project => {

            if(project.getAttribute('data-category') === attributeSelected) {
                project.classList.add('project--active');
                e.target.classList.add('button--active');
            } else {
                document.querySelector('.button--first-category').classList.remove('button--active');
                project.classList.remove('project--active');
                e.target.classList.remove('button--active');
            }
        });
    }));

    // Loop MouseLeave sur les boutons de navigation - Check si les attributs correspondent 
    navProjects.forEach( nav => nav.addEventListener('mouseleave', function(e){
        e.preventDefault();
        let projectActive = document.querySelector('.project-list-detailed--active');

        e.target.classList.remove('button--active');
        
        if(projectActive) {
            if(nav.getAttribute('data-category') === projectActive.getAttribute('data-category')) { 
                nav.classList.add('button--active');
            } else {
                nav.classList.remove('button--active');
            }
        }
    }));

    // Loop MouseEnter sur les vignettes de projet - Check si les attributs correspondent 
    projects.forEach( project => project.addEventListener('mouseenter', function(e) {
        let attributeSelected = this.getAttribute('data-category');

        navProjects.forEach(project => {
            if(project.getAttribute('data-category') === attributeSelected) {
                project.classList.add('button--hovered');
            } else {
                document.querySelector('.button--first-category').classList.remove('button--active');
                project.classList.remove('button--hovered');
            }
        });
    }));

    // Loop Mouseleave sur les vignettes de projet - Check si les attributs correspondent 
    projects.forEach( project => project.addEventListener('mouseleave', function(e) {
        navProjects.forEach(navProject => {
            navProject.classList.remove('button--hovered');
        });
    }));

    // Loop Click sur les vignettes de projet - Check si les attributs correspondent 
    projects.forEach( project => project.addEventListener('click', function(e){
        e.preventDefault();
        let attributeSelected = this.getAttribute('data-category');

        projectsCategoryDetailed.forEach(projectCategory => {
            if(projectCategory.getAttribute('data-category') === attributeSelected) {
                projectCategory.classList.add('project-list-detailed--active');
            } else {
                projectCategory.classList.remove('project-list-detailed--active');
            }
        });
        navProjects.forEach(navProject => {
            if(navProject.getAttribute('data-category') === attributeSelected) {
                navProject.classList.add('button--active');
                navProject.focus();
            } else {
                navProject.classList.remove('button--active');
                navProject.blur();
            }
        });
    }));

    // Slider project - fonction qui permet de récupérer les slides correspondantes au même projet
    function getRelatedElements(rel = '') {
        if(rel) {
            return lightboxImages.filter((lightBoxImages) => lightBoxImages.hasAttribute('rel') && lightBoxImages.getAttribute('rel') === rel);
        }

        return [];
    }

    // Slider project - fonction qui permet de récupérer les contenu précedent au click 
    function onPrevLightboxContentClick(e) {
        const relatedElements = [...lightboxInstance.element().querySelectorAll('.lightbox-content-element')];
        const currentFocusedEl = lightboxInstance.element().querySelector('.current');
        
        currentFocusedEl.classList.remove('current');
        currentFocusedEl.classList.add('d-none');

        if(currentFocusedEl.previousElementSibling && currentFocusedEl.previousElementSibling.classList.contains('lightbox-content-element')) {
            currentFocusedEl.previousElementSibling.classList.remove('d-none');
            currentFocusedEl.previousElementSibling.classList.add('current');
        }
        else {
            relatedElements[relatedElements.length - 1].classList.remove('d-none');
            relatedElements[relatedElements.length - 1].classList.add('current');
        }
    }

    // Slider project - fonction qui permet de récupérer les contenu suivant au click 
    function onNextLightboxContentClick(e) {
        const relatedElements = [...lightboxInstance.element().querySelectorAll('.lightbox-content-element')];
        const currentFocusedEl = lightboxInstance.element().querySelector('.current');
        
        currentFocusedEl.classList.remove('current');
        currentFocusedEl.classList.add('d-none');
        
        if(currentFocusedEl.nextElementSibling && currentFocusedEl.nextElementSibling.classList.contains('lightbox-content-element')) {
            currentFocusedEl.nextElementSibling.classList.remove('d-none');
            currentFocusedEl.nextElementSibling.classList.add('current');
        }
        else {
            relatedElements[0].classList.remove('d-none');
            relatedElements[0].classList.add('current');
        }
    }

    // Slider project - fonction qui permet de fermer la lightbox au click
    function closePopup(e) {
        const lightbox = document.querySelector('.basicLightbox');
        lightbox.remove();
    }
});