class TeamMembersList {
    
    constructor(el = null) {
        this.el = el;

        if(this.el) {
            this.init();
        }
    }

    init() {
        this.infosContent = this.el.querySelector('.team__member-infos-content');
        this.members = this.el.querySelector('.team-members');
        this.displayedMember = null;

        this.bindEvents();
    }

    bindEvents() {
        this.el.addEventListener('click', this.onClick.bind(this));
    }

    onClick(e) {
        let {target} = e;
    
        if(this.displayedMember) {
            this.displayedMember.classList.remove('team__member--active');
        }

        this.displayedMember = null;

        if(target.classList.contains('team__member') || target.classList.contains('team__member-visual')) {
            
            if(target.classList.contains('team__member-visual')) {
                this.displayedMember = target.closest('.team__member');
            }
            if(!this.displayedMember.classList.contains('team__member--active')) {
                this.displayedMember.classList.add('team__member--active');   
            }
        }

        Array.from(this.el.children).forEach(function (element) {
            if(!element.classList.contains('team__member--active') && element.classList.contains('team__member')) {
                element.classList.remove('team__member--active');                
            } else {
                element.classList.remove('team__member--disabled');
            }
         });
    }
}

export default TeamMembersList;